<template>
	<router-link
		:to="{ path: '/articles/tag/' + props.tag.slug }"
		class="tag has-text-grey-light"
		v-bind:class="{ 'is-dark': isDark }"
		v-bind:style="{ 'border-color': props.tag.color }"
	>
		<strong>{{ tag.name }}</strong>
	</router-link>
</template>

<script lang="ts" setup>
import { type PropType } from "vue";
import * as data from "../services/StashAPI";

const props = defineProps({
	isDark: {
		type: Boolean,
		default: false,
	},
	tag: {
		type: Object as PropType<data.Tag>,
		default: null,
	},
});
</script>

<style scoped lang="scss">
@import "../css/bulma.variables.scss";

.tag {
	background-color: rgba($grey-dark, 0.7);
	margin-left: 0.5rem !important;
	margin-right: 0 !important;
}
</style>

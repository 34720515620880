<template>
	<div v-html="html" class="content markdown-content"></div>
</template>
<script lang="ts" setup>
import { computed, onMounted, ref } from "vue";
import * as marked from "marked";
import hljs from "highlight.js";

const markedOptions = {
	highlight: (code, lang) => {
		const language = hljs.getLanguage(lang) ? lang : "plaintext";
		return hljs.highlight(code, { language }).value;
	},
	smartLists: true,
};

const props = defineProps({
	md: {
		type: String,
		default: "",
	},
});

const html = computed(() => {
	console.log("Rendered");
	return marked.marked(props.md, markedOptions);
});

onMounted(() => {
	const renderer = {
		image(href: string, title: string, text: string) {
			return `<figure><a href="${href}"><img src="${href}" alt="${text}" loading="lazy" /></a><figcaption>${text}</figcaption></figure>`;
		},
	};

	marked.marked.use({ renderer });
});
</script>
<style lang="scss">
.markdown-content {
	.clear {
		clear: both;
	}

	figure {
		margin: 2em auto;

		img {
			display: block;
		}

		figcaption {
			color: #7f7c92;
		}
	}

	code[class*="language-"] {
		background: transparent;
	}

	img[alt~="left"] {
		float: left;
		margin-right: 1rem;
	}

	img[alt~="center"] {
		display: block;
		margin: auto;
	}

	img[alt~="right"] {
		float: right;
		margin-left: 1rem;
	}

	img[alt~="size-400"] {
		max-width: 400px;
	}

	pre {
		background: #191820 !important;
	}

	p {
		line-height: 2rem;
		text-align: justify;
	}
}
</style>
<style scoped>
.gallery p {
	display: flex;
}

.gallery p img {
	/* flex-basis: 100px; */
	flex-grow: 1;
	width: 100px;
}

.gallery p img:not(last-child) {
	margin-right: 1px;
}

.gallery ul li {
	background-color: red;
	/* display: block; */
	list-style-type: none;
	width: 100%;
	height: 250px;
}

.gallery ul li img {
	display: none;
	object-fit: cover;
	width: 100%;
	height: 100%;
}
</style>

<template>
	<div class="article-card">
		<!-- Quote -->
		<a
			v-bind:href="props.article.author_link"
			target="_blank"
			v-if="article.type == 'quote'"
			class="article-card-quote"
		>
			<div>
				<span class="las la-quote-left is-size-1"></span>
				<span class="title">{{ props.article.quote }}</span>
				<span class="las la-quote-right is-size-1"></span>
				<div class="author has-text-right">-&nbsp;{{ props.article.author }}</div>
			</div>
		</a>

		<!-- Regular -->
		<router-link
			:to="{ path: '/article/' + article.slug }"
			v-else
			class="article-card article-card-regular"
		>
			<div class="card hover">
				<div
					v-if="props.article.thumbnail"
					class="card-background is-overlay"
					v-bind:style="{ 'background-image': 'url(' + article.thumbnail + ')' }"
				></div>

				<div class="card-gradient is-overlay"></div>

				<div class="card-content">
					<h2 class="title">{{ article.title }}</h2>
					<p class="subtitle is-size-6 mb-2">
						{{ formatDate(article.published) }}
					</p>
					<p class="description">{{ article.description }}</p>
				</div>

				<div class="card-footer pt-2 pb-2">
					<div class="tags m-auto">
						<tag v-for="tag in article.tags" :tag="tag" />
					</div>
				</div>
			</div>
		</router-link>
	</div>
</template>

<script lang="ts" setup>
import { type PropType } from "vue";

import { formatDate } from "@/services/DateTimeService";
import { Article } from "@/services/StashAPI";
import Tag from "../components/Tag.vue";

const props = defineProps({
	article: Object as PropType<Article>,
});
</script>

<style lang="scss" scoped>
@import "../css/bulma.variables.scss";

.article-card {
	transition: transform 0.3s;

	@media (min-width: 769px) {
		&:hover {
			transform: scale(1.05);
		}
	}

	.article-card-quote {
		a {
			display: block;
			margin-top: 2rem !important;

			.author {
				margin-top: 1rem;
			}
		}
	}
}

.tags .tag {
	margin-bottom: 0 !important;
}

.card-content {
	min-height: 150px;
}

@media (min-width: 769px) {
	.card-content {
		height: 220px;
	}
}

.card-background {
	background-position: center;
	background-size: cover;
	opacity: 0.5;
	pointer-events: none;
}

.card-gradient {
	background: linear-gradient(348deg, #00000000 40%, #00000099 80%);
}

.card-content {
	position: relative;
	text-shadow: 1px 1px 0 #000;
	z-index: 1;
}

.card {
	border: 1px transparent solid;
	transition: border 0.3s;

	&.hover {
		transition: border 0.3s, box-shadow 0.3s;

		&:hover {
			box-shadow: 3px 3px 5px #7433d255;
			border: 1px $primary solid;
		}
	}
}

.card .card-footer,
.card .card-footer-item {
	border-color: $primary;
}

.card-footer {
	background-color: #00000066 !important;
	position: relative;
	z-index: 1;
}
</style>

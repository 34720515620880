<template>
	<one-column>
		<markdown-viewer v-if="article" :md="article.content" />
	</one-column>
</template>
<script lang="ts" setup>
import { onMounted, ref } from "vue";

import OneColumn from "../layouts/OneColumn.vue";

import MarkdownViewer from "../components/MarkdownViewer.vue";

import * as data from "../services/StashAPI";

const api: data.StashAPI = data.StashAPI.get();
const article = ref(null);

onMounted(async () => {
	article.value = await api.getArticleAsync("2000-01-01-about");
});
</script>

<style lang="scss" scoped></style>

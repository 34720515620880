<template>
	<two-column>
		<loader v-if="!isLoaded" />

		<div v-for="article in articles" :key="article.slug">
			<project-card :article="article" />
		</div>

		<template v-slot:sidebar>
			<div class="content">
				<h2>Art</h2>

				<p>Stuff that I do in my free time.</p>
			</div>
		</template>
	</two-column>
</template>
<script lang="ts" setup>
import { onMounted, ref } from "vue";

import * as data from "../services/StashAPI";

import Loader from "../components/Loader.vue";
import ProjectCard from "../components/ProjectCard.vue";
import TwoColumn from "../layouts/TwoColumn.vue";

const api: data.StashAPI = data.StashAPI.get();

const isLoaded = ref(false);
const articles = ref([]);

onMounted(async () => {
	await loadArticlesAsync();
});

async function loadArticlesAsync(): Promise<void> {
	isLoaded.value = false;

	articles.value = await api.getArticlesAsync(0, 5000, "art");

	isLoaded.value = true;
}
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
	.section {
		padding-top: 1.5rem !important;
	}
}
</style>

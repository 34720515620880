<template>
	<div class="project-card">
		<router-link :to="{ path: '/article/' + props.article.slug }">
			<div class="is-relative" style="height: 400px">
				<div
					class="project-background-image is-overlay"
					v-bind:style="{ 'background-image': 'url(' + props.article.cover + ')' }"
				></div>

				<div class="bg-gradient is-overlay"></div>

				<div class="project-text is-overlay">
					<div class="project-title">
						{{ props.article.title }}
					</div>
					<div class="project-description">
						{{ props.article.description }}
					</div>
					<div></div>
				</div>

				<div class="project-footer">
					<tag v-for="tag in props.article.tags" :tag="tag" />
				</div>
			</div>
		</router-link>
	</div>
</template>

<script lang="ts" setup>
import { type PropType } from "vue";
import { Article } from "@/services/StashAPI";
import Tag from "./Tag.vue";

const props = defineProps({
	article: Object as PropType<Article>,
});
</script>

<style lang="scss" scoped>
@import "../css/bulma.variables.scss";

.project-card {
	padding: 0 0 0.75rem 0 !important;
	transition: transform 0.3s;

	@media (min-width: 769px) {
		&:hover {
			transform: scale(1.02);
		}
	}

	.bg-gradient {
		background: linear-gradient(348deg, #00000000 40%, #00000099 80%);
	}

	.project-background-image {
		background-position: center;
		background-size: cover;
	}

	.project-text {
		color: white;
		width: 50%;
		padding: 1em;

		.project-title {
			font-size: 2em;
		}

		.project-description {
			margin-left: 0.2em;
			margin-top: 1em;
		}
	}
	.project-footer {
		background-color: #00000099;
		border-top: 1px $primary solid;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 0.5em;
		text-align: right;
	}
}
</style>

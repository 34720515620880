<template>
	<div class="loader"></div>
</template>
<script lang="ts" setup></script>
<style>
.loader {
	animation: spinAround 500ms infinite linear;

	border: 2px solid #843dea;
	border-radius: 290486px;
	border-right-color: transparent;
	border-top-color: transparent;

	content: "";

	position: relative;
	display: block;
	width: 1em;
	height: 1em;

	font-size: 2em;
	margin: 0 auto;
}
</style>

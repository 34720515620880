<template>
	<two-column>
		<loader v-if="!isLoaded" />

		<div v-for="article in articles" :key="article.slug">
			<project-card :article="article" />
		</div>

		<template v-slot:sidebar>
			<div class="content">
				<h2>Projects</h2>

				<p>These are some of the projects I've been working on outside of my day job.</p>

				<p>
					Personal projects are a fun way to learn new stuff, or solve a problem in a
					day-to-day workflow.
				</p>

				<p>
					Most of these are also available on my
					<a href="https://github.com/flyingpie">GitHub page</a>.
				</p>

				<p class="has-text-centered">
					<a href="https://github.com/flyingpie">
						<img style="opacity: 0.5; width: 50%" src="/img/github-logo.webp" />
					</a>
				</p>
			</div>
		</template>
	</two-column>
</template>
<script lang="ts" setup>
import { onMounted, ref } from "vue";

import TwoColumn from "../layouts/TwoColumn.vue";
import ProjectCard from "../components/ProjectCard.vue";
import Loader from "../components/Loader.vue";

import * as data from "../services/StashAPI";

const api: data.StashAPI = data.StashAPI.get();

const isLoaded = ref(false);
const articles = ref(null);

onMounted(async () => {
	await loadArticlesAsync();
});

async function loadArticlesAsync(): Promise<void> {
	isLoaded.value = false;

	articles.value = await api.getArticlesAsync(0, 5000, "project");

	isLoaded.value = true;
}
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
	.section {
		padding-top: 1.5rem !important;
	}
}
</style>

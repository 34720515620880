<template>
	<nav
		class="navbar"
		role="navigation"
		aria-label="main navigation"
		v-bind:class="{ darker: isNavbarDarker }"
	>
		<div class="navbar-background-image is-hidden-touch is-overlay"></div>

		<div class="container">
			<div class="navbar-brand is-hidden-desktop is-relative">
				<div class="navbar-background-image is-overlay"></div>

				<a class="navbar-item" href="/">
					<img src="/img/logo-navbar.webp" height="28" />
				</a>

				<a
					v-bind:class="{ 'is-active': isNavbarOpen }"
					v-on:click="isNavbarOpen = !isNavbarOpen"
					role="button"
					class="navbar-burger"
					aria-label="menu"
					aria-expanded="false"
					data-target="navbarBasicExample"
				>
					<span aria-hidden="true"></span>
					<span aria-hidden="true"></span>
					<span aria-hidden="true"></span>
				</a>
			</div>

			<div
				id="navbarBasicExample"
				class="navbar-menu"
				v-bind:class="{ 'is-active': isNavbarOpen }"
			>
				<div class="navbar-start">
					<router-link to="/" class="navbar-item" active-class="is-active">
						<span class="las la-15x la-circle-notch"></span>&nbsp;Articles
					</router-link>

					<router-link to="/projects" class="navbar-item" ActiveClass="is-active">
						<span class="las la-15x la-check-circle"></span>&nbsp;Projects
					</router-link>

					<a href="https://www.github.com/flyingpie" class="navbar-item">
						<span class="lab la-15x la-github"></span>&nbsp;GitHub
					</a>

					<router-link to="/art" class="navbar-item" ActiveClass="is-active">
						<span class="las la-15x la-paint-brush"></span>&nbsp;Art
					</router-link>

					<router-link to="/about" class="navbar-item" ActiveClass="is-active">
						<span class="las la-15x la-question-circle"></span>&nbsp;About
					</router-link>
				</div>

				<div class="navbar-end">
					<div class="navbar-item">
						<form @submit.stop.prevent="search">
							<div class="field has-addons">
								<button type="submit" class="button">
									<i class="las la-15x la-search"></i>
								</button>

								<input
									class="input has-text-light has-background-dark"
									type="text"
									placeholder="Search"
									v-model="searchTerm"
								/>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</nav>
</template>

<script lang="ts" setup>
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();

const isNavbarOpen = ref(false);
const isNavbarDarker = ref(false);

const searchTerm = ref("");

onMounted(() => {
	if (typeof route.params.searchTerm === "string") {
		searchTerm.value = route.params.searchTerm;
	}

	window.addEventListener("scroll", () => {
		isNavbarDarker.value = document.body.getBoundingClientRect().top < -70;
	});
});

async function search(): Promise<void> {
	if (searchTerm.value) {
		router.push(`/articles/search/${searchTerm.value}`);
	} else {
		router.push(`/articles`);
	}
}
</script>

<style lang="scss" scope>
@import "../css/bulma.variables.scss";

.navbar {
	transition: background-color, 0.5s;
}

@media (max-width: 1023px) {
	.navbar.darker {
		background-color: #22054a !important;
	}
}

.navbar-background-image {
	background-image: url("/img/navbar-background.webp");
	background-size: cover;
	opacity: 0.4;
}
</style>

import { createApp } from "vue";
import App from "./App.vue";

import { router } from "./router";

import "@fontsource/raleway";
import "line-awesome/dist/line-awesome/css/line-awesome.min.css";

import "highlight.js/styles/vs2015.css";

import "./css/bulma.scss";

const app = createApp(App);

app.use(router).mount("#app");

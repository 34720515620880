<template>
	<div
		class="has-text-centered has-background-grey-darker tags"
		style="padding: 0.5rem 0 0 0; z-index: 999"
		clas="tag-list"
	>
		<div class="m-auto">
			<tag v-for="tag in tags" :tag="tag" />
		</div>
	</div>
</template>

<script lang="ts" setup>
import { Ref, onMounted, ref } from "vue";

import * as data from "../services/StashAPI";

import Tag from "../components/Tag.vue";

const api: data.StashAPI = data.StashAPI.get();

const tags: Ref<data.Tag[]> = ref();

onMounted(async () => {
	tags.value = await api.getTagsAsync();
});
</script>

<style lang="scss">
.tag {
	margin-left: 0.5rem !important;
	margin-right: 0 !important;
}
</style>

<template>
	<aside class="menu" style="position: sticky; top: 5rem">
		<ul class="menu-list">
			<li v-for="h1 in props.toc.headings" :key="h1.title">
				<a href="#" v-bind:class="{ 'is-active': props.activeTitle == h1.title }">{{
					h1.title
				}}</a>

				<ul>
					<li v-for="h2 in h1.headings" :key="h2.title">
						<a href="#" v-bind:class="{ 'is-active': props.activeTitle == h2.title }">{{
							h2.title
						}}</a>
					</li>
				</ul>
			</li>
		</ul>
	</aside>
</template>
<script lang="ts" setup>
const props = defineProps({
	toc: Object,
	activeTitle: String,
});
</script>

<template>
	<main-layout>
		<section class="section">
			<div class="container">
				<loader v-if="!isLoaded" />

				<div class="columns is-variable is-four-fifths" v-if="article">
					<div class="column">
						<h2 class="title">{{ article.title }}</h2>

						<div class="is-flex">
							<div class="is-flex-grow-2 has-text-left subtitle">
								{{ formatDate(article.created) }}
							</div>
							<div>
								<div class="tags">
									<tag
										v-for="tag in article.tags"
										:key="tag.slug"
										:tag="tag"
										:isDark="true"
									/>
								</div>
							</div>
						</div>

						<p class="is-italic has-text-grey-light mb-4">
							{{ article.description }}
						</p>
					</div>
				</div>
			</div>

			<div v-if="article?.cover" class="cover-image">
				<div class="container">
					<div
						class="front"
						v-bind:style="{
							'background-image': 'url(' + article.cover + ')',
						}"
					></div>
				</div>
			</div>

			<div class="container" v-if="article">
				<div class="columns is-variable is-four-fifths">
					<div class="column">
						<markdown-viewer :md="text" />
					</div>
					<div class="column is-one-fifth is-hidden-touch" v-if="article.toc">
						<table-of-contents :toc="article.toc" />
					</div>
				</div>
			</div>
		</section>
	</main-layout>
</template>
<script lang="ts" setup>
import { Ref, onMounted, ref } from "vue";
import { useRoute } from "vue-router";

import MainLayout from "../layouts/MainLayout.vue";

import Loader from "../components/Loader.vue";
import MarkdownViewer from "../components/MarkdownViewer.vue";
import TableOfContents from "../components/TableOfContents.vue";
import Tag from "../components/Tag.vue";

import { formatDate } from "../services/DateTimeService";
import * as data from "../services/StashAPI";

const api: data.StashAPI = data.StashAPI.get();
const route = useRoute();

const isLoaded = ref(false);
const text = ref("");
const article: Ref<data.Article> = ref(null);

onMounted(async () => {
	window.scrollTo(0, 0);

	let slug = route.params.slug;

	try {
		article.value = await api.getArticleAsync(slug);
		text.value = article.value.content;
	} catch (error) {
		if (error.response?.status == 404) {
			text.value = "Page not found :(";
		} else {
			text.value = "Something went wrong :(";
		}
	}

	isLoaded.value = true;
});
</script>
<style lang="scss" scoped>
.cover-image {
	background-color: #1b171e;
	margin: 0 -10em 4em -10em;
	position: relative;

	.front {
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		height: 300px;
	}
}

@media (min-width: 1280px) {
	.cover-image {
		.front {
			height: 500px;
		}
	}
}
</style>

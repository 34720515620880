<template>
	<footer class="footer has-background-grey-darker">
		<div class="content has-text-centered">
			<p>
				<strong>Flyingpie.nl</strong> by
				<a href="mailto:marco@flyingpie.nl">Marco van den Oever</a>.
			</p>
			<p><strong>Bulma</strong> by <a href="https://jgthms.com">Jeremy Thomas</a>.</p>
			<p>&copy; {{ new Date().getFullYear() }}</p>
		</div>
	</footer>
</template>
<script lang="ts" setup></script>

import { createRouter, createWebHistory } from "vue-router";

import About from "./views/About.vue";
import Art from "./views/Art.vue";
import Article from "./views/Article.vue";
import Articles from "./views/Articles.vue";
import Projects from "./views/Projects.vue";

const routes = [
	{
		path: "/",
		alias: ["/articles", "/articles/search/:searchTerm?", "/articles/tag/:tag?"],
		component: Articles,
		name: "articles",
	},
	{
		path: "/article/:slug",
		component: Article,
		name: "article",
	},
	{
		path: "/art",
		component: Art,
		name: "art",
	},
	{
		path: "/about",
		component: About,
		name: "about",
	},
	{
		path: "/projects",
		component: Projects,
		name: "projects",
	},
];

export const router = createRouter({
	history: createWebHistory(),
	routes,
});

<template>
	<one-column>
		<loader v-if="!isLoaded" />

		<router-link
			:to="{ path: '/articles' }"
			v-if="searchTerm || tag"
			v-on:click="clearFilter()"
			class="article-filter"
		>
			<span v-if="searchTerm"
				>Articles containing term '<strong>{{ searchTerm }}</strong
				>'</span
			>
			<span v-if="tag"
				>Articles with tag '<strong>{{ tag }}</strong
				>'</span
			>
			&nbsp;<span class="las la-times"></span>
		</router-link>

		<div class="columns is-multiline">
			<!-- 2-column -->
			<div
				v-for="article in articles"
				:key="article.slug"
				class="column is-hidden-desktop"
				v-bind:class="{
					'is-half': !article.wide2,
					'is-full': article.wide2,
				}"
			>
				<article-card :article="article" />
			</div>

			<!-- 3-column -->
			<div
				v-for="article in articles"
				:key="article.slug"
				class="column is-hidden-touch"
				v-bind:class="{
					'is-one-third-desktop': !article.wide3,
					'is-two-thirds-desktop': article.wide3,
				}"
			>
				<article-card :article="article" />
			</div>
		</div>

		<button
			v-on:click="loadMore()"
			v-if="!searchTerm && !tag && hasMoreArticles"
			class="button"
			style="display: block; margin: auto"
		>
			Load more
		</button>
	</one-column>
</template>
<script lang="ts" setup>
import OneColumn from "../layouts/OneColumn.vue";

import ArticleCard from "../components/ArticleCard.vue";
import Loader from "../components/Loader.vue";

import * as data from "../services/StashAPI";
import { onMounted, onUpdated, Ref, ref } from "vue";

import { useRoute } from "vue-router";

const api: data.StashAPI = data.StashAPI.get();
const route = useRoute();

const tag: Ref<string> = ref(null);
const searchTerm: Ref<string> = ref(null);

const isLoaded: Ref<boolean> = ref(false);
const articles: Ref<data.Article[]> = ref([]);

const articlesFrom: Ref<number> = ref(0);
const articlesCount: Ref<number> = ref(21);
const hasMoreArticles: Ref<boolean> = ref(true);

onMounted(async () => {
	await update();
});

onUpdated(async () => {
	await update();
});

async function update() {
	tag.value = null;
	searchTerm.value = null;

	if (typeof route.params.tag === "string") {
		console.log("with tag");
		tag.value = route.params.tag;
	}

	if (typeof route.params.searchTerm === "string") {
		console.log(`with search term '${route.params.searchTerm}'`);
		searchTerm.value = route.params.searchTerm;
	}

	await loadArticlesAsync();
}

function clearFilter() {
	tag.value = null;
	searchTerm.value = null;
}

async function loadMore() {
	articlesFrom.value = articlesFrom.value + articlesCount.value;

	const moreArticles = await api.getArticlesAsync(articlesFrom.value, articlesCount.value);

	articles.value = articles.value.concat(moreArticles);

	hasMoreArticles.value = moreArticles.length >= articlesCount.value;
}

async function loadArticlesAsync(): Promise<void> {
	articlesFrom.value = 0;
	isLoaded.value = false;

	if (searchTerm.value) {
		console.log(`search '${searchTerm.value}'`);
		articles.value = await api.searchArticlesAsync(searchTerm.value);
	} else if (tag.value) {
		articles.value = await api.getArticlesAsync(0, 5000, tag.value);
	} else {
		articles.value = await api.getArticlesAsync(articlesFrom.value, articlesCount.value);
	}

	isLoaded.value = true;
	console.log(`Loaded ${articles.value.length} articles`);
}
</script>

<style lang="scss" scoped>
@import "../css/bulma.variables.scss";

@media (max-width: 768px) {
	.section {
		padding-top: 1.5rem !important;
	}

	.column {
		padding: 0 0 0.75rem 0 !important;
	}
}

.article-filter {
	background-color: $grey-darker;
	border: 1px $grey-darker solid;
	color: white;
	display: inline-block;
	margin-bottom: 1em;
	padding: 0.5em 1em;

	&:hover {
		border: 1px $primary solid;
	}
}
</style>

import axios, { AxiosResponse } from "axios";
import * as JsSearch from "js-search";

export class Articles {
	articles: Article[];
	tags: Tag[];
}

export class Article {
	author?: string;
	author_link?: string;
	content?: string;
	cover?: string;
	created: string;
	description?: string;
	hidden?: boolean;
	prefer_wide: boolean;
	tags?: Tag[];
	thumbnail?: string;
	title?: string;
	toc?: TableOfContents;
	type?: string;
	slug: string;
	wide2: boolean;
	wide3: boolean;
	published?: string;
	quote?: string;
}

export class Tag {
	color: string;
	count: number;
	name: string;
	slug: string;
}

export class TableOfContents {}

export class StashAPI {
	private static _instance?: StashAPI;

	public static get() {
		if (this._instance == null) {
			this._instance = new StashAPI();
		}

		return this._instance;
	}

	private articles: Articles = null;
	private search: JsSearch.Search = null;

	async getArticlesAsync(from: number, to: number, tag?: string): Promise<Article[]> {
		let articles = (await this.getArticlesDataAsync()).articles;

		articles = articles.filter((a) => !a.hidden);

		if (tag) {
			articles = articles.filter((a) => a.tags && a.tags.some((t) => t.name == tag));
		}

		let w2 = 0;
		let w3 = 0;

		for (const article of articles) {
			w2++;
			w3++;

			article.wide2 = article.prefer_wide && w2 % 2 != 0;
			article.wide3 = article.prefer_wide && w3 % 3 != 0;

			if (article.wide2) w2++;
			if (article.wide3) w3++;
		}

		return articles.splice(from, to);
	}

	public async searchArticlesAsync(term: string): Promise<Article[]> {
		await this.buildSearchIndexAsync();

		return this.search.search(term);
	}

	async getArticleAsync(slug: string): Promise<Article> {
		const articles = await this.getArticlesDataAsync();

		for (const article of articles.articles) {
			if (article.slug == slug) {
				return article;
			}
		}

		return null;
	}

	async getTagsAsync(): Promise<Tag[]> {
		return (await this.getArticlesDataAsync()).tags;
	}

	private _axiosResp: Promise<AxiosResponse<Articles>> = null;

	private async getArticlesDataAsync(): Promise<Articles> {
		if (!this._axiosResp) {
			const today = new Date().toISOString().slice(0, 10);
			this._axiosResp = axios.get<Articles>(`/data/articles.json?c=${today}`);
		}

		return (await this._axiosResp).data;
	}

	private async buildSearchIndexAsync(): Promise<void> {
		if (this.search) {
			return;
		}

		const t0 = performance.now();

		this.search = new JsSearch.Search("slug");

		this.search.addIndex("title");
		this.search.addIndex("description");
		this.search.addIndex("content");
		this.search.addIndex("tags_flat");

		const articles = await this.getArticlesDataAsync();

		this.search.addDocuments(articles.articles);

		const t1 = performance.now();

		console.log(`Index built in ${t1 - t0}ms`);
	}
}
